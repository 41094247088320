import React from 'react';
import { css } from '@emotion/core';

//styles
import { SPAN } from '../../elements/typography';

const OfficesFilter = ({ offices, handleFilterOffices, selectedOffice }) => {
  const filterContainerStyles = css`
    margin-bottom: 96px;
    text-align: center;
  `;
  const buttonStyles = css`
    background: transparent;
    color: white;
    border: 1px solid transparent;
    border-radius: 100px;
    padding: 0 1.25em;
    margin: 0 0.5em;
    min-height: 40px;
    text-transform: uppercase;
    cursor: pointer;
    transition: border 250ms ease;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
    &.active {
      border: 1px solid white;
    }
  `;
  return (
    <div css={filterContainerStyles}>
      <button
        css={buttonStyles}
        onClick={() => handleFilterOffices('reset')}
        className={selectedOffice === null && 'active'}
      >
        <SPAN>All</SPAN>
      </button>
      {offices &&
        offices.map(office => {
          return (
            <button
              key={office.id}
              onClick={() => handleFilterOffices(office.id)}
              className={office.id === selectedOffice && 'active'}
              css={buttonStyles}
            >
              <SPAN>{office.name}</SPAN>
            </button>
          );
        })}
    </div>
  );
};

export default OfficesFilter;
