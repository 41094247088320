import React from 'react';
import { css } from '@emotion/core';

import Job from './job';

//styles
import { P } from '../../elements/typography';

const Department = ({ department }) => {
  return (
    <div
      key={department.id}
      css={css`
        margin-bottom: 32px;
        padding-top: 8px;
        opacity: ${department.jobs.length ? 1 : 0.2};
      `}
    >
      <div>
        <div>
          <P
            css={css`
              margin-bottom: 8px;
              text-transform: uppercase;
              display: inline-block;
              margin-right: 8px;
              &:before {
                content: '';
                width: 13px;
                height: 13px;
                background-color: white;
                display: inline-block;
                margin-right: 8px;
                border-radius: 50%;
              }
            `}
          >
            {department.name}
          </P>
        </div>

        <div>
          {department.jobs.map(job => {
            return <Job job={job} key={job.id} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Department;
