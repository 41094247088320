import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { setColorMode } from '../utilities/functions';

//components
import HeroText from '../components/hero-text';
import { Container } from '../elements/layouts';
import Footer from '../components/footer';
import JobListing from '../components/jobListing';

const Jobs = ({ data: { datoCmsJob }, location }) => {
  useEffect(() => {
    setColorMode(location.pathname);
  }, [location.pathname]);

  const { htmlAst } = datoCmsJob.headlineNode.childMarkdownRemark;
  return (
    <>
      <HeroText headline={htmlAst} />
      <Container px={128}>
        <JobListing />
      </Container>
      <Footer />
    </>
  );
};

export default Jobs;

export const query = graphql`
  query JobsQuery {
    datoCmsJob {
      headlineNode {
        childMarkdownRemark {
          htmlAst
        }
      }
    }
  }
`;
