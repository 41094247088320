import React from 'react';
import { css } from '@emotion/core';

//styles
import { SPAN, LINK } from '../../elements/typography';

const Job = ({ job }) => {
  return (
    <div
      key={job.id}
      css={css`
        display: flex;
        justify-content: space-between;
        border-top: 1px solid white;
        padding-top: 16px;
        margin-bottom: 16px;
      `}
    >
      <SPAN
        css={css`
          margin-right: 8px;
          display: inline-block;
        `}
      >
        {job.title}
      </SPAN>

      <LINK
        css={css`
          border-bottom: 1px solid;
        `}
        href={job.absolute_url}
      >
        Apply Now
      </LINK>
    </div>
  );
};

export default Job;
