import React from 'react';
import { css } from '@emotion/core';

import Department from './department';

import { H2, P } from '../../elements/typography';
import { useEffect } from 'react';
import { useState } from 'react';

const Departments = ({ office }) => {
  const [hasJobs, setHasJobs] = useState(false);

  const checkJobs = arr => {
    let result = arr.departments.filter(department => {
      return department.jobs.length > 0;
    });
    return result.length;
  };
  useEffect(() => {
    if (checkJobs(office) > 0) {
      setHasJobs(true);
    } else {
      setHasJobs(false);
    }
  }, [office]);

  if (hasJobs) {
    return (
      <div
        css={css`
          margin-bottom: 96px;
        `}
      >
        <H2
          css={css`
            border-top: 1px solid white;
            margin-bottom: 64px;
            padding-top: 8px;
          `}
        >
          {office.name}
        </H2>
        {office.departments.map(department => {
          if (department.jobs.length) {
            return <Department department={department} key={department.id} />;
          } else return null;
        })}
      </div>
    );
  } else {
    return (
      <div>
        <H2
          css={css`
            border-top: 1px solid white;
            margin-bottom: 64px;
            padding-top: 8px;
          `}
        >
          {office.name}
        </H2>
        <P
          css={css`
            margin-bottom: 96px;
            padding-top: 8px;
          `}
        >
          No open positions at the moment.
        </P>
      </div>
    );
  }
};

export default Departments;
