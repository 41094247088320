import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';

//components
import OfficesFilter from './officesFilter';
import Departments from './departments';

const JobListing = () => {
  const [offices, setOffices] = useState([]);
  const [filteredOffices, setFilteredOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleFilterOffices = id => {
    if (id === 'reset') {
      setFilteredOffices(offices);
      setSelectedOffice(null);
    } else {
      const filteredArray = offices.filter(office => {
        return office.id === id;
      });
      setFilteredOffices(filteredArray);
      setSelectedOffice(id);
    }
  };

  useEffect(() => {
    const URL = 'https://boards-api.greenhouse.io/v1/boards/titelmedia/offices';
    fetch(URL)
      .then(response => response.json())
      .then(data => {
        //filter out "NO OFFICE" item
        const realOffices = data.offices.filter(office => office.id !== 0);

        setOffices(realOffices);
        setFilteredOffices(realOffices);
        setSelectedOffice(null);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div
        css={css`
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 0 0 128px 0;
          text-transform: uppercase;
        `}
      >
        (Loading Jobs)
      </div>
    );
  }

  return (
    <div
      css={css`
        min-height: 50vh;
      `}
    >
      <OfficesFilter
        offices={offices}
        handleFilterOffices={handleFilterOffices}
        selectedOffice={selectedOffice}
      />
      {filteredOffices.map(office => (
        <Departments office={office} key={office.id} />
      ))}
    </div>
  );
};

export default JobListing;
